import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Grid, TextField } from "@material-ui/core";
import { useStudies } from "app/services/studiesService";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  defaultFUDateFrom,
  defaultDateTo,
  SummaryAccrual,
  MultiSite,
  RecruitmentStatusOptions,
} from "app/shared/constants";
import isEmpty from "lodash/isEmpty";
import { Autocomplete } from "app/UI/Autocomplete/Autocomplete";

const StudyDetailsTab = (props) => {
  const { loginHB, initData, Controller, hookcontrol, errors } = props;
  const { t } = useTranslation();
  const { therapeuticAreas, fundingSources, allHonestBrokers } = useStudies();
  const [honestBroker, setHonestBroker] = useState(
    initData.honestBroker ? initData.honestBroker : loginHB
  );

  const addTwoYearsToTheCurrentDate = () => {
    const currentDate = new Date(Date.now());

    // add 2 years to the current date
    currentDate.setFullYear(currentDate.getFullYear() + 2);

    return currentDate;
  };

  return !isEmpty(initData) ? (
    <Grid container justify="space-between" spacing={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="protocolDepartment"
          label={t(`formLabel.department`)}
          variant="outlined"
          value={initData.protocolDeptName || ""}
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="division"
          label={t(`formLabel.division`)}
          defaultValue={initData.division}
          variant="outlined"
          inputRef={hookcontrol.register({ required: true })}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          error={errors.division ? true : false}
          helperText={
            errors.division ? t("formValidation.requiredField") : null
          }
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          as={
            <TextField
              fullWidth
              select
              label={t(`formLabel.therapeuticArea`)}
              variant="outlined"
              size="small"
            >
              <MenuItem value={0}>{t("formLabel.none")}</MenuItem>
              {therapeuticAreas &&
                therapeuticAreas.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
            </TextField>
          }
          name="therapeuticArea"
          control={hookcontrol}
          defaultValue={initData.therapeuticArea ? initData.therapeuticArea : 0}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="honestBroker"
          control={hookcontrol}
          rules={{
            required: {
              value: true,
              message: t("formValidation.requiredField"),
            },
            validate: (value) => {
              if (!value || !value.id) {
                return "Please select a valid option.";
              }
              return true;
            },
          }}
          defaultValue={honestBroker ?? null}
          render={({ onChange, ...props }) => (
            <Autocomplete
              {...props}
              id="honestBroker"
              key="honest_Broker"
              size="small"
              options={allHonestBrokers || []}
              getOptionLabel={(option) => option && option.displayName}
              getOptionDisabled={(option) => !option.currentHonestBroker}
              getOptionSelected={(option, value) => option.id === value?.id}
              label={t("formLabel.honestBroker")}
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              value={honestBroker ?? null}
              onChange={(e, newValue) => {
                setHonestBroker(newValue);
                onChange(newValue);

                if (
                  newValue &&
                  hookcontrol.getValues().recruitmentStatus ===
                    RecruitmentStatusOptions[6]
                ) {
                  hookcontrol.setValue(
                    "recruitmentStatus",
                    RecruitmentStatusOptions[0]
                  );
                }

                // trigger validation for `recruitmentStatus` after updating its value
                hookcontrol.trigger("recruitmentStatus");
              }}
              clientSide={true}
              error={errors.honestBroker ? true : false}
              helperText={
                errors.honestBroker ? t("formValidation.requiredField") : null
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="cancerCenter"
          label={t(`formLabel.cancerCenterLabel`)}
          variant="outlined"
          value={
            initData.protocolCancerCenter !== undefined
              ? initData.protocolCancerCenter
                ? "Yes"
                : "No"
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="studySource"
          label={t(`formLabel.studySourceLabel`)}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={initData.source || ""}
          disabled
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="piInitiated"
          label={t(`formLabel.piInitiated`)}
          value={initData.protocolInvestigatorInitiated || ""}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          as={
            <TextField
              fullWidth
              select
              label={t(`formLabel.fundingSource`)}
              variant="outlined"
              size="small"
            >
              <MenuItem value={0}>{t("formLabel.none")}</MenuItem>
              {fundingSources &&
                fundingSources.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
            </TextField>
          }
          name="fundingSource"
          control={hookcontrol}
          defaultValue={initData.fundingSource ? initData.fundingSource : 0}
        />
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            as={
              <KeyboardDatePicker
                disableToolbar
                autoOk
                fullWidth
                variant="inline"
                inputVariant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="dense"
                format="MM/dd/yyyy"
                label={t(`formLabel.recruitmentStartDate`)}
                minDate={defaultFUDateFrom}
                maxDate={defaultDateTo}
                error={errors.recruitmentStartDate ? true : false}
                helperText={
                  errors.recruitmentStartDate
                    ? t("formValidation.requiredField")
                    : null
                }
              />
            }
            name="recruitmentStartDate"
            control={hookcontrol}
            rules={{ required: true }}
            defaultValue={initData.recruitmentStartDate || new Date(Date.now())}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            as={
              <KeyboardDatePicker
                disableToolbar
                autoOk
                fullWidth
                variant="inline"
                inputVariant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="dense"
                format="MM/dd/yyyy"
                label={t(`formLabel.recruitmentEndDate`)}
                minDate={defaultFUDateFrom}
                maxDate={defaultDateTo}
                error={errors.recruitmentEndDate ? true : false}
                helperText={
                  errors.recruitmentEndDate
                    ? t("formValidation.requiredField")
                    : null
                }
              />
            }
            name="recruitmentEndDate"
            control={hookcontrol}
            rules={{ required: true }}
            defaultValue={
              initData.recruitmentEndDate || addTwoYearsToTheCurrentDate()
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          name="protocolSummaryAccrual"
          label={t(`formLabel.summaryAccrual`)}
          value={
            initData.protocolSummaryAccrual
              ? SummaryAccrual.SummaryAccrualTrue
              : SummaryAccrual.SummaryAccrualFalse || ""
          }
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="protocolMultisiteTrial"
          label={t(`formLabel.multiSite`)}
          value={
            initData.protocolMultisiteTrial
              ? MultiSite.MultiSiteTrue
              : MultiSite.MultiSiteFalse || ""
          }
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          size="small"
        />
      </Grid>
    </Grid>
  ) : null;
};

export default StudyDetailsTab;
