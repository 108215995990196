import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  Grid,
  Divider,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useStyles } from "app/outreach/OutreachDetailsStyles";
import PropTypes from "prop-types";
import { getDisplayPercentage } from "app/shared/utils";
import { useFormik } from "formik";
import axios from "axios";
import { Alert, useAlerts } from "common";
import { URLs, StudyChannelOptions } from "app/shared/constants";
import { useHistory, useLocation } from "react-router-dom";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";

const OutreachDetails = (props) => {
  const {
    onClose,
    open,
    outreach,
    refresh,
    setRefresh,
    readOnly = false,
    summaryAccrual = false,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const { alert, setAlert, clearAlert } = useAlerts();
  const [updatedMatrixData, setUpdatedMatrixData] = useState(outreach || {});
  const history = useHistory();
  const location = useLocation();
  //to extract the studyId from pathname
  const index1 = location.pathname.lastIndexOf("/");
  const studyId = location.pathname.substring(10, index1);

  const [openASConfirmation, setOpenASConfirmation] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [show, setShow] = useState(false);

  const collectData = (outreach) => {
    return {
      requestedQuantity: renderData(outreach.requestedQuantity),
      quantity: renderData(outreach.quantity),
      outreachSummary: outreach.outreachSummary,
      responseSummary: outreach.responseSummary,
      subjectSummary: outreach.subjectSummary,
    };
  };

  const renderData = (value) => {
    if (isNaN(parseInt(value))) {
      return 0;
    }
    return value;
  };

  const renderAbandonedUnsubscribedData = (value) => {
    if (isNaN(parseInt(value))) {
      return t(`ConductOutreachPage.tableColumnNA`);
    } else {
      return value;
    }
  };

  const renderPercentage = (value) => {
    if (isNaN(parseInt(value))) {
      return `${getDisplayPercentage(0)} %`;
    }
    return `${getDisplayPercentage(value)} %`;
  };

  const renderAbandonedUnsubscribedDataPercentage = (value) => {
    if (isNaN(parseInt(value))) {
      return t(`ConductOutreachPage.tableColumnNA`);
    } else {
      return `${getDisplayPercentage(value)} %`;
    }
  };

  const convertDataToExcel = (value) => {
    return [
      renderData(value.requestedQuantity),
      renderData(value.quantity),
      renderData(value.outreachSummary.bounced),
      renderPercentage(value.outreachSummary.bouncedPercentage),
      renderData(value.outreachSummary.failed),
      renderPercentage(value.outreachSummary.failedPercentage),
      renderData(value.outreachSummary.abandoned),
      renderPercentage(value.outreachSummary.abandonedPercentage),
      renderData(value.responseSummary.responded),
      renderPercentage(value.responseSummary.respondedPercentage),
      renderData(value.responseSummary.interested),
      renderPercentage(value.responseSummary.interestedPercentage),
      renderData(value.responseSummary.notInterested),
      renderPercentage(value.responseSummary.notInterestedPercentage),
      renderData(value.responseSummary.unsubscribed),
      renderPercentage(value.responseSummary.unsubscribedPercentage),
      renderData(value.subjectSummary.pepOnStudy),
      renderPercentage(value.subjectSummary.pepOnStudyPercentage),
    ].join("\t");
  };

  const intialData = collectData(updatedMatrixData);

  const formik = useFormik({
    initialValues: intialData,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      handleSaveMatrixData(values, setSubmitting);
    },
  });

  const abandonedValidation = (outreach) => {
    return outreach.channel !== StudyChannelOptions.DE && edit;
  };

  const unsubscribedValidation = (outreach) => {
    return (
      (outreach.channel === StudyChannelOptions.MC ||
        outreach.channel === StudyChannelOptions.MH) &&
      edit
    );
  };

  const pepOnStudyValidation = () => {
    return summaryAccrual && edit;
  };

  const editValidation = () => {
    return (
      summaryAccrual &&
      edit &&
      (outreach.channel === StudyChannelOptions.MC ||
        outreach.channel === StudyChannelOptions.MH)
    );
  };

  const checkNum = (val) => {
    if (isNaN(val) || val === undefined) {
      return 0;
    }
    return val;
  };

  const respondedCalculation = (values) => {
    const responseSummaryData = values;
    const totalNum = formik.values.quantity;

    const num =
      checkNum(parseInt(responseSummaryData.interested)) +
      checkNum(parseInt(responseSummaryData.notInterested)) +
      checkNum(parseInt(responseSummaryData.unsubscribed));

    const percentage = (num / totalNum) * 100;
    const updatedData = {
      ...values,
      responded: num,
      respondedPercentage: percentage,
    };

    formik.setValues((prevValues) => ({
      ...prevValues,
      // we use the name to tell Formik which key of `values` to update
      responseSummary: updatedData,
    }));
  };

  const calculatePercentage = (num) => {
    return (num / intialData.quantity) * 100;
  };

  const handleSaveMatrixData = (values, setSubmitting) => {
    (async () => {
      try {
        const response = await axios.put(
          `${URLs.GET_OUTREACH_URL_BASE}/${outreach.id}${URLs.PUT_OUTREACH_MATRIX_URL_BASE}`,
          values
        );

        const location = {
          state: null,
        };
        history.replace(location);
        setRefresh(!refresh);
        setEdit(false);
        setSubmitting(true);
        setUpdatedMatrixData(response.data);
        setShow(false);
      } catch (err) {
        // set error.message in AlertContext
        const location = {
          pathname: `/outreach/${studyId}/conduct`,
          state: { ...history.location.state, fromOutreachDetailsPopup: true },
        };
        history.replace(location);
        setAlert(
          "error",
          `${err.response?.data?.message || err.message}`,
          false,
          false
        );
        setEdit(false);
        setSubmitting(true);
        setUpdatedMatrixData(formik.values);
        setShow(true);
      }
    })();
  };

  const handleClickCancel = () => {
    if (formik.dirty) {
      setOpenASConfirmation(true);
    } else {
      onClose();
      setShow(true);
      clearAlert();
    }
  };
  const closeCancelASConfirmation = (cancelChoice) => {
    setOpenASConfirmation(false);
    if (cancelChoice) {
      onClose();
      clearAlert();
      setShow(true);
    }
  };

  const setFieldError = (formValue) => {
    const values = formValue;
    const responseSummaryData = values.responseSummary;
    const outreachSummaryData = values.outreachSummary;
    const subjectSummaryData = values.subjectSummary;
    const outreachQuantity = formik.values.quantity;

    const num =
      checkNum(parseInt(outreachSummaryData.bounced)) +
      checkNum(parseInt(outreachSummaryData.failed)) +
      checkNum(parseInt(responseSummaryData.abandoned)) +
      checkNum(parseInt(responseSummaryData.interested)) +
      checkNum(parseInt(responseSummaryData.notInterested)) +
      checkNum(parseInt(responseSummaryData.unsubscribed));

    const pepNumCalculation =
      outreachQuantity -
      (checkNum(parseInt(outreachSummaryData.bounced)) +
        checkNum(parseInt(outreachSummaryData.failed)));

    if (
      pepNumCalculation > 0 &&
      checkNum(parseInt(subjectSummaryData.pepEnrolled)) > pepNumCalculation
    ) {
      const location = {
        pathname: `/outreach/${studyId}/conduct`,
        state: { ...history.location.state, fromOutreachDetailsPopup: true },
      };
      history.replace(location);
      setIsValid(false);
      setAlert(
        "error",
        t(`OutreachDetails.pepOnStudyQuantityError`, {
          quantity: checkNum(parseInt(pepNumCalculation)),
        }),
        false,
        false
      );
      setShow(true);
    } else if (num > outreachQuantity) {
      const location = {
        pathname: `/outreach/${studyId}/conduct`,
        state: { ...history.location.state, fromOutreachDetailsPopup: true },
      };
      history.replace(location);
      setIsValid(false);
      setAlert(
        "error",
        t(`OutreachDetails.matricsSumQuantityError`),
        false,
        false
      );
      setShow(true);
    } else {
      setIsValid(true);
      setShow(false);
    }
  };

  const editDisableValidation = () => {
    return ![
      "FINISHED",
      "FINISHED_WITH_ERROR",
      "GENERATED",
      "GENERATED_WITH_ERROR",
    ].includes(outreach.status);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"paper"}
      maxWidth={"xl"}
      fullWidth={false}
    >
      <ConfirmationDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        message={t(`OutreachDetails.confirmationMessageCancel`)}
        okLabel={t("ConductOutreachPage.yesLabel")}
        cancelLabel={t("ConductOutreachPage.noLabel")}
      />
      <DialogTitle
        id="outreach-quantity-summary-title"
        className={classes.OutreachDetailsTitle}
      >
        {t(`OutreachDetails.title`, outreach)}
      </DialogTitle>
      {show && alert.exists && <Alert />}
      <form>
        <DialogContent>
          <CopyButton
            labelText={t(`OutreachDetails.copyToClipboard`)}
            clipboardText={convertDataToExcel(updatedMatrixData)}
            active={edit}
          />

          <TableContainer component={Paper}>
            <Table className={classes.outrachTableOutline} size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="135" align="center">
                    {t(`OutreachDetails.requestedQuantity`)}
                  </TableCell>
                  <TableCell width="135" align="center">
                    {t(`OutreachDetails.outreachQuantity`)}
                  </TableCell>
                  <TableCell align="center">
                    {t(`OutreachDetails.bounced`)}
                  </TableCell>
                  <TableCell align="center">
                    {t(`OutreachDetails.failed`)}
                  </TableCell>
                  <TableCell align="center">
                    {t(`OutreachDetails.abandoned`)}
                  </TableCell>
                  <TableCell align="center">
                    {t(`OutreachDetails.responded`)}
                  </TableCell>
                  <TableCell align="center">
                    {t(`OutreachDetails.interested`)}
                  </TableCell>
                  <TableCell align="center">
                    {t(`OutreachDetails.notInterested`)}
                  </TableCell>
                  <TableCell align="center">
                    {t(`OutreachDetails.unsubscribed`)}
                  </TableCell>
                  <TableCell align="center">
                    {t(`OutreachDetails.pepOnStudy`)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.outreachTableCellCustomPadding}>
                <TableRow>
                  <TableCell>
                    <Grid container>
                      <Grid item xs>
                        <InputBase
                          disabled
                          value={
                            formik.values.requestedQuantity &&
                            renderData(formik.values.requestedQuantity)
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs>
                        <InputBase
                          disabled
                          value={
                            formik.values.quantity &&
                            renderData(formik.values.quantity)
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs>
                        <InputBase
                          name="bounced"
                          id="bounced"
                          disabled={editValidation() || !edit}
                          value={
                            formik.values.outreachSummary
                              ? renderData(
                                  formik.values.outreachSummary.bounced
                                )
                              : ""
                          }
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              (e.target.value >= 0 &&
                                e.target.value <= formik.values.quantity)
                            ) {
                              const newVal = {
                                ...formik.values.outreachSummary,
                                bounced: e.target.value,
                                bouncedPercentage: calculatePercentage(
                                  e.target.value
                                ),
                              };
                              formik.setValues((prevValues) => ({
                                ...prevValues,
                                // we use the name to tell Formik which key of `values` to update
                                outreachSummary: newVal,
                              }));
                              const updatedFormValue = {
                                ...formik.values,
                                outreachSummary: newVal,
                              };
                              setFieldError(updatedFormValue);
                            }
                          }}
                        />
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs>
                        <InputBase
                          name="bouncedPercentage"
                          id="bouncedPercentage"
                          disabled
                          value={
                            formik.values.outreachSummary
                              ? renderPercentage(
                                  formik.values.outreachSummary
                                    .bouncedPercentage
                                )
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs>
                        <InputBase
                          name="failed"
                          id="failed"
                          disabled={editValidation() || !edit}
                          value={
                            formik.values.outreachSummary
                              ? renderData(formik.values.outreachSummary.failed)
                              : ""
                          }
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              (e.target.value >= 0 &&
                                e.target.value <= formik.values.quantity)
                            ) {
                              const newVal = {
                                ...formik.values.outreachSummary,
                                failed: e.target.value,
                                failedPercentage: calculatePercentage(
                                  e.target.value
                                ),
                              };
                              formik.setValues((prevValues) => ({
                                ...prevValues,
                                // we use the name to tell Formik which key of `values` to update
                                outreachSummary: newVal,
                              }));
                              const updatedFormValue = {
                                ...formik.values,
                                outreachSummary: newVal,
                              };
                              setFieldError(updatedFormValue);
                            }
                          }}
                        />
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs>
                        <InputBase
                          name="failedPercentage"
                          id="failedPercentage"
                          disabled
                          value={
                            formik.values.outreachSummary
                              ? renderPercentage(
                                  formik.values.outreachSummary.failedPercentage
                                )
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs>
                        <InputBase
                          name="abandoned"
                          id="abandoned"
                          disabled={abandonedValidation(outreach) || !edit}
                          value={
                            formik.values.responseSummary
                              ? renderAbandonedUnsubscribedData(
                                  formik.values.responseSummary.abandoned
                                )
                              : ""
                          }
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              (e.target.value >= 0 &&
                                e.target.value <= formik.values.quantity)
                            ) {
                              const newVal = {
                                ...formik.values.responseSummary,
                                abandoned: e.target.value,
                                abandonedPercentage: calculatePercentage(
                                  e.target.value
                                ),
                              };
                              formik.setValues((prevValues) => ({
                                ...prevValues,
                                // we use the name to tell Formik which key of `values` to update
                                responseSummary: newVal,
                              }));
                              const updatedFormValue = {
                                ...formik.values,
                                responseSummary: newVal,
                              };
                              setFieldError(updatedFormValue);
                            }
                          }}
                        />
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs>
                        <InputBase
                          name="abandonedPercentage"
                          id="abandonedPercentage"
                          disabled
                          value={
                            formik.values.responseSummary
                              ? renderAbandonedUnsubscribedDataPercentage(
                                  formik.values.responseSummary
                                    .abandonedPercentage
                                )
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs>
                        <InputBase
                          name="responded"
                          id="responded"
                          disabled
                          value={
                            formik.values.responseSummary
                              ? renderData(
                                  formik.values.responseSummary.responded
                                )
                              : ""
                          }
                        />
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs>
                        <InputBase
                          name="respondedPercentage"
                          id="respondedPercentage"
                          disabled
                          value={
                            formik.values.responseSummary
                              ? renderPercentage(
                                  formik.values.responseSummary
                                    .respondedPercentage
                                )
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs>
                        <InputBase
                          name="interested"
                          id="interested"
                          disabled={editValidation() || !edit}
                          autoFocus={false}
                          value={
                            formik.values.responseSummary
                              ? renderData(
                                  formik.values.responseSummary.interested
                                )
                              : ""
                          }
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              (e.target.value >= 0 &&
                                e.target.value <= formik.values.quantity)
                            ) {
                              const newVal = {
                                ...formik.values.responseSummary,
                                interested: e.target.value,
                                interestedPercentage: calculatePercentage(
                                  e.target.value
                                ),
                              };
                              formik.setValues((prevValues) => ({
                                ...prevValues,
                                // we use the name to tell Formik which key of `values` to update
                                responseSummary: newVal,
                              }));
                              respondedCalculation(newVal);
                              const updatedFormValue = {
                                ...formik.values,
                                responseSummary: newVal,
                              };
                              setFieldError(updatedFormValue);
                            }
                          }}
                        />
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs>
                        <InputBase
                          name="interestedPercentage"
                          id="interestedPercentage"
                          disabled
                          value={
                            formik.values.responseSummary
                              ? renderPercentage(
                                  formik.values.responseSummary
                                    .interestedPercentage
                                )
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs>
                        <InputBase
                          name="notInterested"
                          id="notInterested"
                          disabled={editValidation() || !edit}
                          value={
                            formik.values.responseSummary
                              ? renderData(
                                  formik.values.responseSummary.notInterested
                                )
                              : ""
                          }
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              (e.target.value >= 0 &&
                                e.target.value <= formik.values.quantity)
                            ) {
                              const newVal = {
                                ...formik.values.responseSummary,
                                notInterested: e.target.value,
                                notInterestedPercentage: calculatePercentage(
                                  e.target.value
                                ),
                              };
                              formik.setValues((prevValues) => ({
                                ...prevValues,
                                // we use the name to tell Formik which key of `values` to update
                                responseSummary: newVal,
                              }));
                              respondedCalculation(newVal);
                              const updatedFormValue = {
                                ...formik.values,
                                responseSummary: newVal,
                              };
                              setFieldError(updatedFormValue);
                            }
                          }}
                        />
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs>
                        <InputBase
                          name="notInterestedPercentage"
                          id="notInterestedPercentage"
                          disabled
                          value={
                            formik.values.responseSummary
                              ? renderPercentage(
                                  formik.values.responseSummary
                                    .notInterestedPercentage
                                )
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs>
                        <InputBase
                          name="unsubscribed"
                          id="unsubscribed"
                          disabled={unsubscribedValidation(outreach) || !edit}
                          value={
                            formik.values.responseSummary
                              ? renderAbandonedUnsubscribedData(
                                  formik.values.responseSummary.unsubscribed
                                )
                              : ""
                          }
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              (e.target.value >= 0 &&
                                e.target.value <= formik.values.quantity)
                            ) {
                              const newVal = {
                                ...formik.values.responseSummary,
                                unsubscribed: e.target.value,
                                unsubscribedPercentage: calculatePercentage(
                                  e.target.value
                                ),
                              };
                              formik.setValues((prevValues) => ({
                                ...prevValues,
                                // we use the name to tell Formik which key of `values` to update
                                responseSummary: newVal,
                              }));
                              respondedCalculation(newVal);
                              const updatedFormValue = {
                                ...formik.values,
                                responseSummary: newVal,
                              };
                              setFieldError(updatedFormValue);
                            }
                          }}
                        />
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs>
                        <InputBase
                          name="unsubscribedPercentage"
                          id="unsubscribedPercentage"
                          disabled
                          value={
                            formik.values.responseSummary
                              ? renderAbandonedUnsubscribedDataPercentage(
                                  formik.values.responseSummary
                                    .unsubscribedPercentage
                                )
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs>
                        <InputBase
                          name="pepEnrolled"
                          id="pepEnrolled"
                          disabled={!pepOnStudyValidation()}
                          value={
                            formik.values.subjectSummary
                              ? renderData(
                                  formik.values.subjectSummary.pepEnrolled
                                )
                              : ""
                          }
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              (e.target.value >= 0 &&
                                e.target.value <= formik.values.quantity)
                            ) {
                              const newVal = {
                                ...formik.values.subjectSummary,
                                pepEnrolled: e.target.value,
                                pepEnrolledPercentage: calculatePercentage(
                                  e.target.value
                                ),
                              };
                              formik.setValues((prevValues) => ({
                                ...prevValues,
                                // we use the name to tell Formik which key of `values` to update
                                subjectSummary: newVal,
                              }));
                              const updatedFormValue = {
                                ...formik.values,
                                subjectSummary: newVal,
                              };
                              setFieldError(updatedFormValue);
                            }
                          }}
                        />
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item xs>
                        <InputBase
                          name="pepEnrolledPercentage"
                          id="pepEnrolledPercentage"
                          disabled
                          value={
                            formik.values.subjectSummary
                              ? renderPercentage(
                                  formik.values.subjectSummary
                                    .pepEnrolledPercentage
                                )
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          {edit ? (
            <Button
              onClick={formik.handleSubmit}
              color="primary"
              disabled={
                !formik.isValid ||
                !formik.dirty ||
                !isValid ||
                formik.isSubmitting
              }
            >
              {t(`OutreachDetails.save`)}
            </Button>
          ) : !readOnly ? (
            <Button
              color="primary"
              onClick={() => setEdit(true)}
              disabled={
                editDisableValidation() ||
                ((outreach.channel === StudyChannelOptions.MC ||
                  outreach.channel === StudyChannelOptions.MH) &&
                  !summaryAccrual)
              }
            >
              {t(`Edit`)}
            </Button>
          ) : null}
          {edit ? (
            <Button onClick={() => handleClickCancel()} color="primary">
              {t(`OutreachDetails.cancel`)}
            </Button>
          ) : (
            <Button
              onClick={() => {
                onClose();
                clearAlert();
              }}
              color="primary"
            >
              {t(`Close`)}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};
OutreachDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  outreach: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    requestedQuantity: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    outreachSummary: PropTypes.object.isRequired,
    responseSummary: PropTypes.object.isRequired,
    subjectSummary: PropTypes.object.isRequired,
    channel: PropTypes.string,
  }),
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  readOnly: PropTypes.bool,
  summaryAccrual: PropTypes.bool,
};

const CopyButton = (props) => {
  const { t } = useTranslation();
  const { clipboardText, labelText, active } = props;
  const [buttonLabel, setButtonLabel] = useState(labelText);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(clipboardText).then(
      () => {
        setTemporaryButtonLabel(t(`OutreachDetails.copySuccessful`));
      },
      () => {
        setTemporaryButtonLabel(t(`OutreachDetails.copyFailed`));
      }
    );
  };

  const setTemporaryButtonLabel = (text) => {
    setButtonLabel(text);
    setTimeout(() => setButtonLabel(labelText), 3000);
  };

  return (
    <Button onClick={copyToClipboard} color="primary" disabled={active}>
      {buttonLabel}
    </Button>
  );
};
CopyButton.propTypes = {
  clipboardText: PropTypes.string,
  labelText: PropTypes.string,
  active: PropTypes.bool,
};

export default OutreachDetails;
