import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  CloudUpload as CloudUploadIcon,
  Info as InfoIcon,
} from "@material-ui/icons";
import { useStyles } from "app/prospect/ImportProspectsPageStyles";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";
import PEErrorDialog from "app/shared/UI/PEErrorDialog";
import PEExport from "app/shared/UI/PEExport";
import {
  GET_IMPORTS_URL_BASE,
  GET_STUDIES_URL_BASE,
  ImportStatuses,
  SourceTypeOptions,
} from "app/shared/constants";
import {
  addReverseIndexToElements,
  mapStudyToDisplayed,
} from "app/shared/utils";
import axios from "axios";
import { useAlerts } from "common";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const mapStatus = (originalStatus) => {
  const lowercaseStatus = originalStatus.toLowerCase();
  return lowercaseStatus[0].toUpperCase() + lowercaseStatus.substring(1);
};

const errorSummaryContent = {
  primaryContent: "row",
  secondaryContent: "message",
};

const defaultPageNumber = 0;

const ImportProspectsPageContent = (props) => {
  const { intervalSetter } = props;
  const { state, pathname } = props.location;
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [openASConfirmation, setOpenASConfirmation] = useState(false);
  const [selectedFiles, setSelectdFiles] = useState([]);
  const [studyImports, setStudyImports] = useState([]);
  const refStudyImports = useRef(studyImports);
  const [study, setStudy] = useState(null);
  const [importSource, setImportSource] = useState("STARR_HBE");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImportRequest, setSelectedImportRequest] = useState(-1);
  //const [replaceObsolete, setReplaceObsolete] = useState(false);
  const replaceObsolete = false;
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const classes = useStyles();
  const [importIds, setImportIds] = useState([]);
  const [importFileNames, setImportFileNames] = useState([]);
  const [importFileDuplicate, setImportFileDuplicate] = useState(false);

  const maxUploadFileSizeInMb = 50;

  const postImportProspects = async () => {
    try {
      // call POST API to import chosen file
      const apiHeader = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // import a file of 50MB takes 80s, increase the timeout to accommodate (defined in .env)
        timeout: process.env.REACT_APP_IMPORT_UPLOAD_TIMEOUT,
      };

      const formData = new FormData();
      formData.append("file", selectedFiles[0]);

      const irbNumber = study ? study.irbNumber : state.study.irbNumber;
      const postURL =
        GET_IMPORTS_URL_BASE +
        "/" +
        importSource +
        "/" +
        irbNumber +
        "?obsolete-prospects=" +
        replaceObsolete;

      const response = await axios.post(postURL, formData, apiHeader);

      // reset import section after import successfully
      setSelectdFiles([]);
      // return import_reqest, should be added to the top of the Recent Activity list
      const newstudyImports = [...studyImports];
      newstudyImports.unshift(response.data);
      setStudyImports(newstudyImports);
      refStudyImports.current = newstudyImports;

      const ids = [...importIds, response.data.id];
      setImportIds(ids);
      const fileNames = [...importFileNames, response.data.filename];
      setImportFileNames(fileNames);

      // set a timer to retrieve the status of the import_request everty X seconds(defined in .env)
      // till the status become "Finished"
    } catch (err) {
      // set error.message in AlertContext
      setAlert("error", err.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!state) {
        // in case authorized user refresh the page, so lose the context that normally
        // will pass from study listing
        // pathname pattern is "/prospects/:studyId/import"
        const index1 = pathname.lastIndexOf("/");
        const studyId = pathname.substring(11, index1);

        // fetch study by id
        try {
          const response = await axios.get(
            `${GET_STUDIES_URL_BASE}/${studyId}`
          );

          setStudy(mapStudyToDisplayed(response.data)); // save this for comparison at edit save
        } catch (err) {
          setAlert("error", err.message); // set error.message in AlertContext
        }
      }
    })();
  }, [state, setAlert, pathname]);

  useEffect(() => {
    setTableLoader(true);

    (async () => {
      try {
        // fetch the import recent activities by irbNumber
        if ((state && state.study) || study) {
          const irbNumber = study ? study.irbNumber : state.study.irbNumber;

          const response = await axios.get(
            `${GET_IMPORTS_URL_BASE}?irbNumber=${irbNumber}`,
            {
              timeout: process.env.REACT_APP_IMPORT_HISTORY_TIMEOUT,
            }
          );

          setStudyImports(response.data);
          refStudyImports.current = response.data;

          const fileNames = response.data?.map((row) => row.filename);
          setImportFileNames(fileNames);
        }
      } catch (err) {
        // set error.message in AlertContext
        setAlert("error", err.message);
      } finally {
        setTableLoader(false);
      }
    })();
  }, [state, study, setAlert]);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectdFiles(acceptedFiles);
  }, []);

  const onDropRejected = useCallback(
    (fileRejections) => {
      setAlert(
        "error",
        fileRejections[0].errors
          .map((e) => e.code)
          .map((c) => {
            switch (c) {
              case "file-invalid-type":
                return t("ImportProspectsPage.importFileInvalidTypeMessage", {
                  validFileType: ".csv",
                });
              case "file-too-large":
                return t("ImportProspectsPage.importFileTooLargeMessage", {
                  maxFileSize: maxUploadFileSizeInMb,
                });
              default:
                return t("ImportProspectsPage.importFileGenericError");
            }
          })
          .join(", ")
      );
    },
    [t, setAlert]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    maxSize: maxUploadFileSizeInMb * 1024 * 1024,
    multiple: false,
    onDrop,
    onDropRejected,
  });

  const acceptedFilesItems = selectedFiles.map((file, index) => (
    <span key={index}>
      {file.path} - {file.size} bytes
    </span>
  ));

  const closeCancelASConfirmation = (okOnClose) => {
    setOpenASConfirmation(false);
    setImportFileDuplicate(false);
    if (okOnClose) {
      setLoader(true);
      // click confirmation OK button, proceed to Import
      postImportProspects();
    }
  };

  useEffect(() => {
    var timerVar = [];
    if (importIds.length) {
      //timerId is for associating every import with a unique id which will help us to clear that particular interval
      let timerId = {};
      let nStudyImports = [];
      timerVar = importIds.map((row, idx) => {
        //this is to update nStudyImports with studyImports at every mapping
        nStudyImports = [...refStudyImports.current];

        return setInterval(async () => {
          // call BE API to retrieve the import_request by id
          //this is to update nStudyImports with nStudyImports at every interval call to update multiple rows data simultaniously
          nStudyImports = [...nStudyImports];

          try {
            timerId[row] = timerVar[idx];
            const result = await axios.get(`${GET_IMPORTS_URL_BASE}/${row}`);

            const dataIndex = refStudyImports.current.findIndex(
              (studyImpObj) => studyImpObj.id === row
            );

            nStudyImports[dataIndex] = result.data;
            setStudyImports(nStudyImports);

            if (
              result.data.status === ImportStatuses.FINISHED ||
              result.data.status === ImportStatuses.FINISHED_WITH_ERROR ||
              result.data.status === ImportStatuses.FAILED
            ) {
              clearInterval(timerId[row]);
              //this is to manage every import file ids.
              importIds.shift(row);
              //this is to delete completed timerId from the set.
              delete timerId[row];
            }
          } catch (err) {
            // set error.message in AlertContext
            setAlert("error", err.message);
            clearInterval(timerId.row);
            importIds.shift(row);
            delete timerId[row];
          }
        }, process.env.REACT_APP_IMPORT_PROSPECTS_REFRESH_RATE);
      });

      timerVar.length > 0 && timerVar.map((id) => intervalSetter(id));
    }

    return () => {
      timerVar.length > 0 && timerVar.map((id) => clearInterval(id));
    };
  }, [importIds, intervalSetter, setAlert]);

  const handleClickReset = () => {
    setSelectdFiles([]);
  };

  const handleClickImport = () => {
    if (importFileNames.includes(selectedFiles[0].name))
      setImportFileDuplicate(true);
    setOpenASConfirmation(true);
  };

  const displayImportFailureDialog = (index) => {
    setOpenDialog(true);
    setSelectedImportRequest(index);
  };

  const closeImportFailureDialog = () => {
    setOpenDialog(false);
    setSelectedImportRequest(-1);
  };

  return study || (state && state.study) ? (
    <Grid container>
      <ConfirmationDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        title={t("ImportProspectsPage.confirmation_title")}
        message={
          importFileDuplicate
            ? t("ImportProspectsPage.duplicateImportMessage")
            : t("ImportProspectsPage.confirmation_message")
        }
        okLabel={t("ImportProspectsPage.confirmation_OK")}
        cancelLabel={t("ImportProspectsPage.confirmation_cancel")}
      />
      <Grid container>
        <Grid item xs={2} className={classes.gridContainer}>
          <Typography>
            {`${t("formLabel.irb")}`}
            <Typography variant="subtitle1" display="inline">
              {study ? study.irbNumber : state.study.irbNumber}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={10} className={classes.gridContainer}>
          <Typography>
            {`${t("formLabel.studyNickname")}: `}
            <Typography variant="subtitle1" display="inline">
              {study ? study.nickname : state.study.nickname}
            </Typography>
          </Typography>
        </Grid>
        <Grid container className={classes.importSection}>
          <Grid item xs={12}>
            <TextField
              className={classes.formTextField}
              select
              label={t(`ImportProspectsPage.sourceTypeLabel`)}
              placeholder={t(`ImportProspectsPage.sourceTypeLabel`)}
              value={importSource}
              onChange={(evt) => setImportSource(evt.target.value)}
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            >
              {SourceTypeOptions.map((item) => (
                <MenuItem key={item} value={item}>
                  {`${t("ProspectSourceType." + item)}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            {/*<FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={replaceObsolete}
                  onChange={() => setReplaceObsolete(!replaceObsolete)}
                />
              }
              label={t(`ImportProspectsPage.replacePreviousLabel`)}
            />
            */}
          </Grid>
          <div className={classes.dragDropContainer} {...getRootProps()}>
            <input {...getInputProps()} />
            <CloudUploadIcon />
            <p>{t(`ImportProspectsPage.dragNDropFile`)}</p>
          </div>
          <Grid container>
            {selectedFiles && selectedFiles.length > 0 && (
              <>
                <Grid item xs={12} className={classes.textAlignCenter}>
                  <Typography variant="subtitle1">
                    {t(`ImportProspectsPage.fileToUpload`)}&nbsp;
                    {acceptedFilesItems}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.importActionContainer}>
                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loader}
                        onClick={handleClickImport}
                      >
                        {t(`ImportProspectsPage.importButtonLabel`)}
                      </Button>
                      {loader && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    <Button onClick={handleClickReset}>
                      {t(`ImportProspectsPage.resetButtonLabel`)}
                    </Button>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h2">
            {t(`ImportProspectsPage.importRecentActivities`)}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.tableScroll}>
          <TableContainer component={Paper}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t(`ImportProspectsPage.tableColumnIndex`)}
                  </TableCell>
                  <TableCell>
                    {t(`ImportProspectsPage.tableColumnFileName`)}
                  </TableCell>
                  <TableCell>
                    {t(`ImportProspectsPage.tableColumnIRBNumber`)}
                  </TableCell>
                  <TableCell>
                    {t(`ImportProspectsPage.tableColumnSourceType`)}
                  </TableCell>
                  <TableCell>
                    {t(`ImportProspectsPage.tableColumnStatus`)}
                  </TableCell>
                  <TableCell>
                    {t(`ImportProspectsPage.tableColumnImported`)}
                  </TableCell>
                  <TableCell>
                    {t(`ImportProspectsPage.tableColumnFailed`)}
                  </TableCell>
                  <TableCell>
                    {t(`ImportProspectsPage.tableColumnImportedBy`)}
                  </TableCell>
                  <TableCell>
                    {t(`ImportProspectsPage.tableColumnImportedDate`)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableLoader ? (
                  <TableRow key="-1">
                    <TableCell colSpan="8" align="center">
                      <CircularProgress size={35} />
                    </TableCell>
                  </TableRow>
                ) : (
                  studyImports &&
                  addReverseIndexToElements(
                    studyImports,
                    studyImports.length, // Rows per Page
                    defaultPageNumber,
                    studyImports.length // Total Count
                  ).map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.index}</TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item xs={10}>
                            <PEExport
                              displayName={row.filename}
                              exportURL={`${GET_IMPORTS_URL_BASE}/${row.id}/uploaded-file`}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            {row.status === "UPLOADED" && (
                              <CircularProgress size={20} />
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>{row.irbNumber}</TableCell>
                      <TableCell>
                        {t("ProspectSourceType." + row.source)}
                      </TableCell>
                      <TableCell>{mapStatus(row.status)}</TableCell>
                      <TableCell>
                        {row.summary
                          ? row.summary.rows - row.summary.errors
                          : 0}
                      </TableCell>
                      <TableCell>
                        <div className={classes.failedCellContainer}>
                          {row.summary.errors}
                          {row.summary.report &&
                            row.summary.report.length > 0 && (
                              <>
                                <InfoIcon
                                  color="primary"
                                  onClick={() =>
                                    displayImportFailureDialog(index)
                                  }
                                />
                                {openDialog &&
                                  index === selectedImportRequest && (
                                    <PEErrorDialog
                                      onClose={closeImportFailureDialog}
                                      open={openDialog}
                                      errorsList={row.summary.report}
                                      title={t(
                                        `ImportProspectsPage.importFailureSummaryTitle`
                                      )}
                                      primarySubHeading={t(
                                        `ImportProspectsPage.importFailureLineNumber`
                                      )}
                                      primaryContent={
                                        errorSummaryContent.primaryContent
                                      }
                                      secondaryContent={
                                        errorSummaryContent.secondaryContent
                                      }
                                    />
                                  )}
                              </>
                            )}
                        </div>
                      </TableCell>
                      <TableCell>{row.importedBy}</TableCell>
                      <TableCell>
                        {new Date(row.timestamp).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default ImportProspectsPageContent;
